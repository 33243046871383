import { faker } from '@faker-js/faker';
import { createIdentityResult } from './createIdentityResult';
import type {
  IdentityVerified200,
  IdentityVerified400,
  IdentityVerified404,
  IdentityVerified500,
  IdentityVerifiedHeaderParams,
  IdentityVerifiedMutationResponse,
} from '../models/IdentityVerified';

/**
 * @description Bad request
 */

export function createIdentityVerified400(
  override?: NonNullable<Partial<IdentityVerified400>>
): NonNullable<IdentityVerified400> {
  return undefined;
}
/**
 * @description Not found
 */

export function createIdentityVerified404(
  override?: NonNullable<Partial<IdentityVerified404>>
): NonNullable<IdentityVerified404> {
  return undefined;
}
/**
 * @description Internal server error
 */

export function createIdentityVerified500(
  override?: NonNullable<Partial<IdentityVerified500>>
): NonNullable<IdentityVerified500> {
  return undefined;
}

export function createIdentityVerifiedHeaderParams(
  override: NonNullable<Partial<IdentityVerifiedHeaderParams>> = {}
): NonNullable<IdentityVerifiedHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}
/**
 * @description Identity verified - result contains details on how to proceed from here
 */

export function createIdentityVerified200(
  override?: NonNullable<Partial<IdentityVerified200>>
): NonNullable<IdentityVerified200> {
  return createIdentityResult(override);
}
/**
 * @description Identity verified - result contains details on how to proceed from here
 */

export function createIdentityVerifiedMutationResponse(
  override?: NonNullable<Partial<IdentityVerifiedMutationResponse>>
): NonNullable<IdentityVerifiedMutationResponse> {
  return createIdentityResult(override);
}
