import { getOptionsHandler } from './getOptionsHandler';
import { getApplicationResultHandler } from './getApplicationResultHandler';
import { getApplicationStatusHandler } from './getApplicationStatusHandler';
import { putApplicationEmailHandler } from './putApplicationEmailHandler';
import { getApplicationHandler } from './getApplicationHandler';
import { postApplicationHandler } from './postApplicationHandler';
import { deleteApplicationHandler } from './deleteApplicationHandler';
import { addUserDataHandler } from './addUserDataHandler';
import { submitApplicationHandler } from './submitApplicationHandler';
import { identityVerifiedHandler } from './identityVerifiedHandler';
import { initiateEskatConsentHandler } from './initiateEskatConsentHandler';
import { initiatePsd2ConsentHandler } from './initiatePsd2ConsentHandler';
import { signOutHandler } from './signOutHandler';

export const handlers = [
  getOptionsHandler,
  getApplicationResultHandler,
  getApplicationStatusHandler,
  putApplicationEmailHandler,
  getApplicationHandler,
  postApplicationHandler,
  deleteApplicationHandler,
  addUserDataHandler,
  submitApplicationHandler,
  identityVerifiedHandler,
  initiateEskatConsentHandler,
  initiatePsd2ConsentHandler,
  signOutHandler,
] as const;
