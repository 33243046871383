import { faker } from '@faker-js/faker';
import { createUrl } from './createUrl';
import type {
  InitiatePsd2Consent201,
  InitiatePsd2Consent400,
  InitiatePsd2Consent500,
  InitiatePsd2ConsentHeaderParams,
  InitiatePsd2ConsentMutationResponse,
} from '../models/InitiatePsd2Consent';

/**
 * @description PSD2 failed
 */

export function createInitiatePsd2Consent400(
  override: NonNullable<Partial<InitiatePsd2Consent400>> = {}
): NonNullable<InitiatePsd2Consent400> {
  return {
    ...{ code: faker.helpers.arrayElement<any>([`psd2ConsentUrl`]) },
    ...override,
  };
}
/**
 * @description Internal server error
 */

export function createInitiatePsd2Consent500(
  override?: NonNullable<Partial<InitiatePsd2Consent500>>
): NonNullable<InitiatePsd2Consent500> {
  return undefined;
}

export function createInitiatePsd2ConsentHeaderParams(
  override: NonNullable<Partial<InitiatePsd2ConsentHeaderParams>> = {}
): NonNullable<InitiatePsd2ConsentHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}
/**
 * @description created
 */

export function createInitiatePsd2Consent201(
  override?: NonNullable<Partial<InitiatePsd2Consent201>>
): NonNullable<InitiatePsd2Consent201> {
  return createUrl(override);
}
/**
 * @description created
 */

export function createInitiatePsd2ConsentMutationResponse(
  override?: NonNullable<Partial<InitiatePsd2ConsentMutationResponse>>
): NonNullable<InitiatePsd2ConsentMutationResponse> {
  return createUrl(override);
}
