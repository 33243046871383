import { faker } from '@faker-js/faker';
import { createApplicationResult } from './createApplicationResult';
import type {
  GetApplicationResult200,
  GetApplicationResult302,
  GetApplicationResult404,
  GetApplicationResult500,
  GetApplicationResultHeaderParams,
  GetApplicationResultQueryResponse,
} from '../models/GetApplicationResult';

/**
 * @description Redirecting to login
 */

export function createGetApplicationResult302(
  override: NonNullable<Partial<GetApplicationResult302>> = {}
): NonNullable<GetApplicationResult302> {
  return {
    ...{ Location: faker.string.alpha() },
    ...override,
  };
}
/**
 * @description Not found
 */

export function createGetApplicationResult404(
  override?: NonNullable<Partial<GetApplicationResult404>>
): NonNullable<GetApplicationResult404> {
  return undefined;
}
/**
 * @description Internal server error
 */

export function createGetApplicationResult500(
  override?: NonNullable<Partial<GetApplicationResult500>>
): NonNullable<GetApplicationResult500> {
  return undefined;
}

export function createGetApplicationResultHeaderParams(
  override: NonNullable<Partial<GetApplicationResultHeaderParams>> = {}
): NonNullable<GetApplicationResultHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}
/**
 * @description Ok
 */

export function createGetApplicationResult200(
  override?: NonNullable<Partial<GetApplicationResult200>>
): NonNullable<GetApplicationResult200> {
  return createApplicationResult(override);
}
/**
 * @description Ok
 */

export function createGetApplicationResultQueryResponse(
  override?: NonNullable<Partial<GetApplicationResultQueryResponse>>
): NonNullable<GetApplicationResultQueryResponse> {
  return createApplicationResult(override);
}
