import { faker } from '@faker-js/faker';
import type { ApplicationStatusState } from '../models/ApplicationStatusState';

export function createApplicationStatusState(
  override?: NonNullable<Partial<ApplicationStatusState>>
): NonNullable<ApplicationStatusState> {
  return faker.helpers.arrayElement<any>([
    `initial`,
    `processing`,
    `preapproved`,
    `rejected`,
    `failed`,
  ]);
}
