import { faker } from '@faker-js/faker';
import type { LivingExpenses } from '../models/LivingExpenses';

export function createLivingExpenses(
  override: NonNullable<Partial<LivingExpenses>> = {}
): NonNullable<LivingExpenses> {
  return {
    ...{
      isSharing: faker.datatype.boolean(),
      splitFactor: faker.number.float({}),
    },
    ...override,
  };
}
