import { faker } from '@faker-js/faker';
import { createApplication } from './createApplication';
import type {
  PostApplication200,
  PostApplication201,
  PostApplication404,
  PostApplication500,
  PostApplicationHeaderParams,
  PostApplicationMutationResponse,
} from '../models/PostApplication';

export function createPostApplication201(
  override?: NonNullable<Partial<PostApplication201>>
): NonNullable<PostApplication201> {
  return undefined;
}
/**
 * @description Not found
 */

export function createPostApplication404(
  override?: NonNullable<Partial<PostApplication404>>
): NonNullable<PostApplication404> {
  return undefined;
}
/**
 * @description Internal server error
 */

export function createPostApplication500(
  override?: NonNullable<Partial<PostApplication500>>
): NonNullable<PostApplication500> {
  return undefined;
}

export function createPostApplicationHeaderParams(
  override: NonNullable<Partial<PostApplicationHeaderParams>> = {}
): NonNullable<PostApplicationHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}
/**
 * @description Ok, if sessions is provided and application exists
 */

export function createPostApplication200(
  override?: NonNullable<Partial<PostApplication200>>
): NonNullable<PostApplication200> {
  return createApplication(override);
}
/**
 * @description Ok, if sessions is provided and application exists
 */

export function createPostApplicationMutationResponse(
  override?: NonNullable<Partial<PostApplicationMutationResponse>>
): NonNullable<PostApplicationMutationResponse> {
  return createApplication(override);
}
