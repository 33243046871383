import { faker } from '@faker-js/faker';
import type { ApplicationResult } from '../models/ApplicationResult';

export function createApplicationResult(
  override: NonNullable<Partial<ApplicationResult>> = {}
): NonNullable<ApplicationResult> {
  return {
    ...{ borrowPower: faker.number.float({}) },
    ...override,
  };
}
