import { createApplicationDataOptionsLivingExpenses } from './createApplicationDataOptionsLivingExpenses';
import type { ApplicationDataOptions } from '../models/ApplicationDataOptions';

export function createApplicationDataOptions(
  override: NonNullable<Partial<ApplicationDataOptions>> = {}
): NonNullable<ApplicationDataOptions> {
  return {
    ...{ livingExpenses: createApplicationDataOptionsLivingExpenses() },
    ...override,
  };
}
