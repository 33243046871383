import { faker } from '@faker-js/faker';
import type { EskatErrorCodes } from '../models/EskatErrorCodes';

export function createEskatErrorCodes(
  override?: NonNullable<Partial<EskatErrorCodes>>
): NonNullable<EskatErrorCodes> {
  return faker.helpers.arrayElement<any>([
    `ESKAT_RETRY`,
    `ESKAT_IDENTITY_MISMATCH`,
  ]);
}
