import { faker } from '@faker-js/faker';
import type {
  DeleteApplication204,
  DeleteApplication404,
  DeleteApplication500,
  DeleteApplicationHeaderParams,
  DeleteApplicationMutationResponse,
} from '../models/DeleteApplication';

export function createDeleteApplication204(
  override?: NonNullable<Partial<DeleteApplication204>>
): NonNullable<DeleteApplication204> {
  return undefined;
}
/**
 * @description Not found
 */

export function createDeleteApplication404(
  override?: NonNullable<Partial<DeleteApplication404>>
): NonNullable<DeleteApplication404> {
  return undefined;
}
/**
 * @description Internal server error
 */

export function createDeleteApplication500(
  override?: NonNullable<Partial<DeleteApplication500>>
): NonNullable<DeleteApplication500> {
  return undefined;
}

export function createDeleteApplicationHeaderParams(
  override: NonNullable<Partial<DeleteApplicationHeaderParams>> = {}
): NonNullable<DeleteApplicationHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}

export function createDeleteApplicationMutationResponse(
  override?: NonNullable<Partial<DeleteApplicationMutationResponse>>
): NonNullable<DeleteApplicationMutationResponse> {
  return undefined;
}
