import { faker } from '@faker-js/faker';
import type { EmailBody } from '../models/EmailBody';

export function createEmailBody(
  override: NonNullable<Partial<EmailBody>> = {}
): NonNullable<EmailBody> {
  return {
    ...{ email: faker.internet.email() },
    ...override,
  };
}
