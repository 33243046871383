import { faker } from '@faker-js/faker';
import { createEmailBody } from './createEmailBody';
import type {
  PutApplicationEmail204,
  PutApplicationEmail404,
  PutApplicationEmail500,
  PutApplicationEmailHeaderParams,
  PutApplicationEmailMutationRequest,
  PutApplicationEmailMutationResponse,
} from '../models/PutApplicationEmail';

/**
 * @description No content
 */

export function createPutApplicationEmail204(
  override?: NonNullable<Partial<PutApplicationEmail204>>
): NonNullable<PutApplicationEmail204> {
  return undefined;
}
/**
 * @description Not found
 */

export function createPutApplicationEmail404(
  override?: NonNullable<Partial<PutApplicationEmail404>>
): NonNullable<PutApplicationEmail404> {
  return undefined;
}
/**
 * @description Internal server error
 */

export function createPutApplicationEmail500(
  override?: NonNullable<Partial<PutApplicationEmail500>>
): NonNullable<PutApplicationEmail500> {
  return undefined;
}

export function createPutApplicationEmailHeaderParams(
  override: NonNullable<Partial<PutApplicationEmailHeaderParams>> = {}
): NonNullable<PutApplicationEmailHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}

export function createPutApplicationEmailMutationResponse(
  override?: NonNullable<Partial<PutApplicationEmailMutationResponse>>
): NonNullable<PutApplicationEmailMutationResponse> {
  return undefined;
}

export function createPutApplicationEmailMutationRequest(
  override?: NonNullable<Partial<PutApplicationEmailMutationRequest>>
): NonNullable<PutApplicationEmailMutationRequest> {
  return createEmailBody(override);
}
