import { faker } from '@faker-js/faker';
import type { ApplicationDataOptionsLivingExpenses } from '../models/ApplicationDataOptionsLivingExpenses';

export function createApplicationDataOptionsLivingExpenses(
  override: NonNullable<Partial<ApplicationDataOptionsLivingExpenses>> = {}
): NonNullable<ApplicationDataOptionsLivingExpenses> {
  return {
    ...{
      min: faker.number.float({}),
      max: faker.number.float({}),
      step: faker.number.float({}),
    },
    ...override,
  };
}
