import { faker } from '@faker-js/faker';
import type {
  SubmitApplication201,
  SubmitApplication400,
  SubmitApplication404,
  SubmitApplication500,
  SubmitApplicationHeaderParams,
  SubmitApplicationMutationResponse,
} from '../models/SubmitApplication';

/**
 * @description Created
 */

export function createSubmitApplication201(
  override?: NonNullable<Partial<SubmitApplication201>>
): NonNullable<SubmitApplication201> {
  return undefined;
}
/**
 * @description Bad request
 */

export function createSubmitApplication400(
  override?: NonNullable<Partial<SubmitApplication400>>
): NonNullable<SubmitApplication400> {
  return undefined;
}
/**
 * @description Not found
 */

export function createSubmitApplication404(
  override?: NonNullable<Partial<SubmitApplication404>>
): NonNullable<SubmitApplication404> {
  return undefined;
}
/**
 * @description Internal server error
 */

export function createSubmitApplication500(
  override?: NonNullable<Partial<SubmitApplication500>>
): NonNullable<SubmitApplication500> {
  return undefined;
}

export function createSubmitApplicationHeaderParams(
  override: NonNullable<Partial<SubmitApplicationHeaderParams>> = {}
): NonNullable<SubmitApplicationHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}

export function createSubmitApplicationMutationResponse(
  override?: NonNullable<Partial<SubmitApplicationMutationResponse>>
): NonNullable<SubmitApplicationMutationResponse> {
  return undefined;
}
