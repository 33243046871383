import { faker } from '@faker-js/faker';
import type { IdentityResult } from '../models/IdentityResult';

export function createIdentityResult(
  override: NonNullable<Partial<IdentityResult>> = {}
): NonNullable<IdentityResult> {
  return {
    ...{
      code: faker.helpers.arrayElement<any>([
        `VERIFIED`,
        `VERIFIED_SHOW_RESULT`,
        `COOLDOWN`,
        `CREDIT_BLOCKED`,
      ]),
      cooldownUntilDate: faker.string.alpha(),
    },
    ...override,
  };
}
