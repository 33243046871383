import { faker } from '@faker-js/faker';
import type { Url } from '../models/Url';

export function createUrl(
  override: NonNullable<Partial<Url>> = {}
): NonNullable<Url> {
  return {
    ...{ url: faker.string.alpha() },
    ...override,
  };
}
