import { createChildrenEnum } from './createChildrenEnum';
import { faker } from '@faker-js/faker';
import type { Children } from '../models/Children';

export function createChildren(
  override: NonNullable<Partial<Children>> = {}
): NonNullable<Children> {
  return {
    ...{
      hasChildren: faker.datatype.boolean(),
      children: faker.helpers.arrayElements([createChildrenEnum()]) as any,
    },
    ...override,
  };
}
