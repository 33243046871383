import { faker } from '@faker-js/faker';
import type {
  SignOut200,
  SignOut500,
  SignOutHeaderParams,
  SignOutMutationResponse,
} from '../models/SignOut';

export function createSignOut200(
  override?: NonNullable<Partial<SignOut200>>
): NonNullable<SignOut200> {
  return undefined;
}
/**
 * @description Internal server error
 */

export function createSignOut500(
  override?: NonNullable<Partial<SignOut500>>
): NonNullable<SignOut500> {
  return undefined;
}

export function createSignOutHeaderParams(
  override: NonNullable<Partial<SignOutHeaderParams>> = {}
): NonNullable<SignOutHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}

export function createSignOutMutationResponse(
  override?: NonNullable<Partial<SignOutMutationResponse>>
): NonNullable<SignOutMutationResponse> {
  return undefined;
}
