import { faker } from '@faker-js/faker';
import { createApplicationStatus } from './createApplicationStatus';
import type {
  GetApplicationStatus200,
  GetApplicationStatus404,
  GetApplicationStatus500,
  GetApplicationStatusHeaderParams,
  GetApplicationStatusQueryResponse,
} from '../models/GetApplicationStatus';

/**
 * @description Not found
 */

export function createGetApplicationStatus404(
  override?: NonNullable<Partial<GetApplicationStatus404>>
): NonNullable<GetApplicationStatus404> {
  return undefined;
}
/**
 * @description Internal server error
 */

export function createGetApplicationStatus500(
  override?: NonNullable<Partial<GetApplicationStatus500>>
): NonNullable<GetApplicationStatus500> {
  return undefined;
}

export function createGetApplicationStatusHeaderParams(
  override: NonNullable<Partial<GetApplicationStatusHeaderParams>> = {}
): NonNullable<GetApplicationStatusHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}
/**
 * @description Ok
 */

export function createGetApplicationStatus200(
  override?: NonNullable<Partial<GetApplicationStatus200>>
): NonNullable<GetApplicationStatus200> {
  return createApplicationStatus(override);
}
/**
 * @description Ok
 */

export function createGetApplicationStatusQueryResponse(
  override?: NonNullable<Partial<GetApplicationStatusQueryResponse>>
): NonNullable<GetApplicationStatusQueryResponse> {
  return createApplicationStatus(override);
}
