import { createCarEnum } from './createCarEnum';
import { faker } from '@faker-js/faker';
import type { Cars } from '../models/Cars';

export function createCars(
  override: NonNullable<Partial<Cars>> = {}
): NonNullable<Cars> {
  return {
    ...{
      hasCars: faker.datatype.boolean(),
      cars: faker.helpers.arrayElements([createCarEnum()]) as any,
    },
    ...override,
  };
}
