import { faker } from '@faker-js/faker';
import { createUrl } from './createUrl';
import type {
  InitiateEskatConsent201,
  InitiateEskatConsent400,
  InitiateEskatConsent404,
  InitiateEskatConsent500,
  InitiateEskatConsentHeaderParams,
  InitiateEskatConsentMutationResponse,
} from '../models/InitiateEskatConsent';

/**
 * @description verification failed
 */

export function createInitiateEskatConsent400(
  override: NonNullable<Partial<InitiateEskatConsent400>> = {}
): NonNullable<InitiateEskatConsent400> {
  return {
    ...{ code: faker.helpers.arrayElement<any>([`eskatConsentUrl`]) },
    ...override,
  };
}
/**
 * @description Not found
 */

export function createInitiateEskatConsent404(
  override?: NonNullable<Partial<InitiateEskatConsent404>>
): NonNullable<InitiateEskatConsent404> {
  return undefined;
}
/**
 * @description Internal server error
 */

export function createInitiateEskatConsent500(
  override?: NonNullable<Partial<InitiateEskatConsent500>>
): NonNullable<InitiateEskatConsent500> {
  return undefined;
}

export function createInitiateEskatConsentHeaderParams(
  override: NonNullable<Partial<InitiateEskatConsentHeaderParams>> = {}
): NonNullable<InitiateEskatConsentHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}
/**
 * @description created
 */

export function createInitiateEskatConsent201(
  override?: NonNullable<Partial<InitiateEskatConsent201>>
): NonNullable<InitiateEskatConsent201> {
  return createUrl(override);
}
/**
 * @description created
 */

export function createInitiateEskatConsentMutationResponse(
  override?: NonNullable<Partial<InitiateEskatConsentMutationResponse>>
): NonNullable<InitiateEskatConsentMutationResponse> {
  return createUrl(override);
}
