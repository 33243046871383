import { createHousingEnum } from './createHousingEnum';
import { faker } from '@faker-js/faker';
import type { Housing } from '../models/Housing';

export function createHousing(
  override: NonNullable<Partial<Housing>> = {}
): NonNullable<Housing> {
  return {
    ...{
      housingType: createHousingEnum(),
      rent: faker.number.float({}),
      zipcode: faker.number.float({}),
      livesPrimarilyInDenmark: faker.datatype.boolean(),
    },
    ...override,
  };
}
