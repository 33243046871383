import { createApplicationDataOptions } from './createApplicationDataOptions';
import type {
  GetOptions200,
  GetOptions500,
  GetOptionsQueryResponse,
} from '../models/GetOptions';

/**
 * @description Internal server error
 */

export function createGetOptions500(
  override?: NonNullable<Partial<GetOptions500>>
): NonNullable<GetOptions500> {
  return undefined;
}
/**
 * @description Ok
 */

export function createGetOptions200(
  override?: NonNullable<Partial<GetOptions200>>
): NonNullable<GetOptions200> {
  return createApplicationDataOptions(override);
}
/**
 * @description Ok
 */

export function createGetOptionsQueryResponse(
  override?: NonNullable<Partial<GetOptionsQueryResponse>>
): NonNullable<GetOptionsQueryResponse> {
  return createApplicationDataOptions(override);
}
