import { faker } from '@faker-js/faker';
import type { Loan } from '../models/Loan';

export function createLoan(
  override: NonNullable<Partial<Loan>> = {}
): NonNullable<Loan> {
  return {
    ...{
      amount: faker.number.float({}),
      monthlyPayment: faker.number.float({}),
    },
    ...override,
  };
}
