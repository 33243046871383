import { faker } from '@faker-js/faker';
import type { HousingEnum } from '../models/HousingEnum';

export function createHousingEnum(
  override?: NonNullable<Partial<HousingEnum>>
): NonNullable<HousingEnum> {
  return faker.helpers.arrayElement<any>([
    `communityHousing`,
    `ownerOccupied`,
    `rental`,
    `noRent`,
  ]);
}
