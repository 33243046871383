import { createLoan } from './createLoan';
import { faker } from '@faker-js/faker';
import type { LoansThisYear } from '../models/LoansThisYear';

export function createLoansThisYear(
  override: NonNullable<Partial<LoansThisYear>> = {}
): NonNullable<LoansThisYear> {
  return {
    ...{
      hasTakenLoansThisYear: faker.datatype.boolean(),
      loans: faker.helpers.arrayElements([createLoan()]) as any,
    },
    ...override,
  };
}
