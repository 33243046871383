import { createUserData } from './createUserData';
import { faker } from '@faker-js/faker';
import type { Application } from '../models/Application';

export function createApplication(
  override: NonNullable<Partial<Application>> = {}
): NonNullable<Application> {
  return {
    ...{
      connectedLunar: faker.datatype.boolean(),
      connectedESkat: faker.datatype.boolean(),
      connectedPsd2: faker.datatype.boolean(),
      userData: createUserData(),
    },
    ...override,
  };
}
