import { createHousing } from './createHousing';
import { createLivingExpenses } from './createLivingExpenses';
import { createCars } from './createCars';
import { createChildren } from './createChildren';
import { createLoansThisYear } from './createLoansThisYear';
import type { UserData } from '../models/UserData';

export function createUserData(
  override: NonNullable<Partial<UserData>> = {}
): NonNullable<UserData> {
  return {
    ...{
      housing: createHousing(),
      livingExpenses: createLivingExpenses(),
      cars: createCars(),
      children: createChildren(),
      loansThisYear: createLoansThisYear(),
    },
    ...override,
  };
}
