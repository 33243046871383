import { createApplicationStatusState } from './createApplicationStatusState';
import type { ApplicationStatus } from '../models/ApplicationStatus';

export function createApplicationStatus(
  override: NonNullable<Partial<ApplicationStatus>> = {}
): NonNullable<ApplicationStatus> {
  return {
    ...{ state: createApplicationStatusState() },
    ...override,
  };
}
