import { faker } from '@faker-js/faker';
import type { ChildrenEnum } from '../models/ChildrenEnum';

export function createChildrenEnum(
  override?: NonNullable<Partial<ChildrenEnum>>
): NonNullable<ChildrenEnum> {
  return faker.helpers.arrayElement<any>([
    `child-category-1`,
    `child-category-2`,
    `child-category-3`,
  ]);
}
