import { faker } from '@faker-js/faker';
import { createApplication } from './createApplication';
import type {
  GetApplication200,
  GetApplication404,
  GetApplication500,
  GetApplicationHeaderParams,
  GetApplicationQueryResponse,
} from '../models/GetApplication';

/**
 * @description Not found (maybe error json blob?)
 */

export function createGetApplication404(
  override?: NonNullable<Partial<GetApplication404>>
): NonNullable<GetApplication404> {
  return undefined;
}
/**
 * @description Internal server error
 */

export function createGetApplication500(
  override?: NonNullable<Partial<GetApplication500>>
): NonNullable<GetApplication500> {
  return undefined;
}

export function createGetApplicationHeaderParams(
  override: NonNullable<Partial<GetApplicationHeaderParams>> = {}
): NonNullable<GetApplicationHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}
/**
 * @description Ok
 */

export function createGetApplication200(
  override?: NonNullable<Partial<GetApplication200>>
): NonNullable<GetApplication200> {
  return createApplication(override);
}
/**
 * @description Ok
 */

export function createGetApplicationQueryResponse(
  override?: NonNullable<Partial<GetApplicationQueryResponse>>
): NonNullable<GetApplicationQueryResponse> {
  return createApplication(override);
}
