import { faker } from '@faker-js/faker';
import { createUserData } from './createUserData';
import type {
  AddUserData204,
  AddUserData400,
  AddUserData404,
  AddUserData500,
  AddUserDataHeaderParams,
  AddUserDataMutationRequest,
  AddUserDataMutationResponse,
} from '../models/AddUserData';

/**
 * @description No content
 */

export function createAddUserData204(
  override?: NonNullable<Partial<AddUserData204>>
): NonNullable<AddUserData204> {
  return undefined;
}
/**
 * @description Bad request
 */

export function createAddUserData400(
  override?: NonNullable<Partial<AddUserData400>>
): NonNullable<AddUserData400> {
  return undefined;
}
/**
 * @description Not found
 */

export function createAddUserData404(
  override?: NonNullable<Partial<AddUserData404>>
): NonNullable<AddUserData404> {
  return undefined;
}
/**
 * @description Internal server error
 */

export function createAddUserData500(
  override?: NonNullable<Partial<AddUserData500>>
): NonNullable<AddUserData500> {
  return undefined;
}

export function createAddUserDataHeaderParams(
  override: NonNullable<Partial<AddUserDataHeaderParams>> = {}
): NonNullable<AddUserDataHeaderParams> {
  return {
    ...{ Cookie: faker.string.alpha() },
    ...override,
  };
}

export function createAddUserDataMutationResponse(
  override?: NonNullable<Partial<AddUserDataMutationResponse>>
): NonNullable<AddUserDataMutationResponse> {
  return undefined;
}

export function createAddUserDataMutationRequest(
  override?: NonNullable<Partial<AddUserDataMutationRequest>>
): NonNullable<AddUserDataMutationRequest> {
  return createUserData(override);
}
